import { gql } from "@apollo/client";

export default gql`
  query($id: String!) {
    theme: fungi_theme(id: $id) {
      base
      light
      dark
    }    
  }
`;
