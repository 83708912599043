import { useState } from "react";
import { gql } from "@apollo/client";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";

const { __USER_POOL_ID__: UserPoolId, __USER_CLIENT_ID__: ClientId } = window;

const isCognito =
  UserPoolId.length > 0 &&
  !UserPoolId.startsWith("%REACT_APP") &&
  ClientId.length > 0 &&
  !ClientId.startsWith("%REACT_APP");

let userPool;

if (isCognito) {
  userPool = new CognitoUserPool({ UserPoolId, ClientId });
}

const getUserData = gql`
  query ($id: UUID!) {
    user: appUsers(condition: { id: $id }) {
      nodes {
        id
        metaData
      }
    }
  }
`;

const updateUserData = gql`
  mutation ($id: UUID!, $patch: JSON!) {
    updateAppUser(input: { id: $id, patch: { metaData: $patch } }) {
      user: appUser {
        id
        metaData
      }
    }
  }
`;

const createUserData = gql`
  mutation ($id: UUID!, $metaData: JSON!) {
    createAppUser(input: { appUser: { id: $id, metaData: $metaData } }) {
      user: appUser {
        id
        metaData
      }
    }
  }
`;

const prepareForCognito = (userData) => {
  const standard = [
    "address",
    "birthdate",
    "email",
    "family_name",
    "gender",
    "given_name",
    "locale",
    "middle_name",
    "name",
    "nickname",
    "phone_number",
    "picture",
    "preferred_username",
    "profile",
    "updated_at",
    "website",
    "zoneinfo",
  ];

  const custom = [
    "custom:title",
    "custom:organization",
    "custom:organization_url",
    "custom:account_type",
    "custom:license_number",
    "custom:license_state",
    "custom:license_expiration"
  ];

  const attributes = Object.keys(userData).reduce((obj, key) => {
    if (standard.includes(key) || custom.includes(key)) {
      obj[key] = userData[key];
    }

    return obj;
  }, {});

  attributes.address = JSON.stringify(attributes.address);

  return attributes;
};

const forgotPassword = (email, callbacks) => {
  const user = new CognitoUser({ Username: email, Pool: userPool });

  user.forgotPassword(callbacks);
};

const confirmPassword = (email, code, password, callbacks) => {
  const user = new CognitoUser({ Username: email, Pool: userPool });

  user.confirmPassword(code, password, callbacks);
};

const confirmRegistration = (email, code, callbacks) => {
  const user = new CognitoUser({ Username: email, Pool: userPool });

  user.confirmRegistration(code, true, (err, result) => {
    if (err) {
      callbacks.onFailure && callbacks.onFailure(err);
    } else {
      callbacks.onSuccess && callbacks.onSuccess(result);
    }
  });
};

const useAuth = (client) => {
  let [currentUser, setCurrentUser] = useState();

  if (!isCognito) {
    return null;
  }

  const populateCurrentUser = (callback) => {
    const user = userPool.getCurrentUser();

    if (user) {
      user.getSession((err, session) => {
        if (err) {
          throw err;
        }

        if (session.isValid()) {
          currentUser = { sub: user.username };

          user.getUserAttributes((err, attributes) => {
            if (err) {
              throw err;
            }
            
            const userAttributes = attributes.reduce((obj, att) => {
              obj[att.Name] = att.Value;
              return obj;
            }, {});

            userAttributes.address = userAttributes.address || "{}";

            userAttributes.groups =
              session.idToken.payload["cognito:groups"] || [];

            userAttributes.signOut = () => user.signOut();

            userAttributes.updateAttributes = (userData) => {
              const preparedAttributes = prepareForCognito(userData);
              const attributes = Object.keys(preparedAttributes).map((key) => {
                return new CognitoUserAttribute({
                  Name: key,
                  Value: preparedAttributes[key],
                });
              });

              user.updateAttributes(attributes, function (err, result) {
                if (err) {
                  //TODO: Better error handling
                  alert(err.message || JSON.stringify(err));
                  return;
                }

                setCurrentUser({ ...userAttributes, ...preparedAttributes });
              });
            };

            userAttributes.updateMetadata = (userData) => {
              const { favorites } = userData;

              client
                .mutate({
                  mutation: updateUserData,
                  variables: {
                    id: userAttributes.sub,
                    patch: { favorites },
                  },
                })
                .then(({ data }) => {
                  if (!data) return;

                  const { metaData } = data.updateAppUser.user;

                  setCurrentUser({ ...userAttributes, ...metaData });
                });
            };

            // Get additional user data from knl.app_user
            client
              .query({
                query: getUserData,
                variables: { id: userAttributes.sub },
              })
              .then(({ data }) => {
                if (!data) return;

                const { metaData } = data.user.nodes[0];

                currentUser = { ...userAttributes, ...metaData };

                setCurrentUser(currentUser);

                callback && callback();
              });
          });
        }
      });
    }
  }

  const login = (credentials, callbacks) => {
    const authDetails = new AuthenticationDetails(credentials);

    const user = new CognitoUser({
      Username: credentials.Username,
      Pool: userPool,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        populateCurrentUser(callbacks.onSuccess);
        //callbacks.onSuccess && callbacks.onSuccess(data);
      },
      onFailure: (err) => {
        setCurrentUser(null);
        callbacks.onFailure && callbacks.onFailure(err);
      },
      newPasswordRequired: (data) => {
        setCurrentUser(null);
        callbacks.newPasswordRequired && callbacks.newPasswordRequired(data);
      },
    });
  };

  const signUp = (credentials, userData, callbacks) => {
    const userAttributes = prepareForCognito(userData);
    const attributes = Object.keys(userAttributes).map(
      (key) =>
        new CognitoUserAttribute({
          Name: key,
          Value: userAttributes[key],
        })
    );

    userPool.signUp(
      credentials.Username,
      credentials.Password,
      attributes,
      null,
      (err, signupData) => {
        if (err) {
          callbacks.onFailure && callbacks.onFailure(err);
        } else {
          client
            .mutate({
              mutation: createUserData,
              variables: {
                id: signupData.userSub,
                metaData: { favorites: [] },
              },
            })
            .then(({ data }) => {
              if (!data) return;

              callbacks.onSuccess && callbacks.onSuccess(signupData);
            });
        }
      }
    );
  };

  if (!currentUser) {
    populateCurrentUser();
  }

  return {
    currentUser,
    login,
    signUp,
    forgotPassword,
    confirmRegistration,
    confirmPassword,
  };
};

export default useAuth;
