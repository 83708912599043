import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/client";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import getTheme from "./getTheme";

const AppRoot = ({ children, ...props }) => {
  const { appId = props.appId } = useParams();

  const darkMode = useMediaQuery("(prefers-color-scheme: dark)", {
    noSsr: true,
  });

  const [theme, setTheme] = useState();
  const { data } = useQuery(getTheme, { variables: { id: appId } });

  if (!data) return null;

  if (!theme) {
    setTheme(data.theme);
    return null;
  }

  const { base, dark, light, ...meta } = theme;
  const mood = darkMode ? dark : light;

  const muiTheme = createTheme({ ...meta, ...base, ...mood });

  return (
    <>
      <Helmet>
        {base.props.css && <link rel="stylesheet" href={base.props.css} />}
      </Helmet>
      <ThemeProvider theme={muiTheme}>
        {children}
      </ThemeProvider>
    </>
  );
};

export default AppRoot;
