import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import CssBaseline from "@material-ui/core/CssBaseline";

import useAuth from "./useAuth";
import AppContext from "./components/AppContext";
import BusyIndicator from "./components/BusyIndicator";
import AppRoot from "./components/AppView/AppRoot";

const client = new ApolloClient({
  link: new HttpLink(), //authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

//const MapEditRoute = React.lazy(() => import("./components/MapEditor"));
//const CreateRoute = React.lazy(() => import("./apps/create"));
const GraphiqlRoute = React.lazy(() => import("./components/GraphQLEditor"));
const DefaultAppRoute = React.lazy(() => import("./components/AppView"));
const Root = React.lazy(() => import("./components/MapBrowser"));

const AppRoutes = {
  grasslots: React.lazy(() => import("./apps/grasslots/client/src")),
  // abandoned_railways: React.lazy(() => import("./apps/abandoned_railways/client/src")),
  // wine: React.lazy(() => import("./apps/wine/client/src")),
  // golf: React.lazy(() => import("./apps/golf/client/src")),
  // cc: React.lazy(() => import("./apps/cc/client/src")),
};

const { __ROOT_APP__, __ROOT_URL__ } = window;

const isRootApp =
  __ROOT_APP__.length > 0 && !__ROOT_APP__.startsWith("%REACT_APP");

//////////////////////////
const DynamicAppRoute = (props) => {
  let { appId } = useParams();

  if (isRootApp) {
    appId = __ROOT_APP__;
  }

  if (typeof AppRoutes[appId] === "undefined") {
    return (
      <AppRoot appId={appId} {...props}>
        <DefaultAppRoute appId={appId} {...props} />
      </AppRoot>
    );
  }

  const RouteComponent = AppRoutes[appId];

  return <RouteComponent appId={appId} {...props} />;
};

const App = () => {
  const auth = useAuth(client);

  return (
    <>
      <CssBaseline />
      <div style={{ height: "100vh", width: "100vw" }}>
        <ApolloProvider client={client}>
          <AppContext.Provider
            value={{
              client,
              ...auth,
            }}
          >
            <React.Suspense fallback={<BusyIndicator />}>
              <Router>
                <Switch>
                  <Route
                    path={`/gql`}
                    exact
                    component={(props) => <GraphiqlRoute {...props} />}
                  />
                  <Route path={`/confirmUser`} component={DynamicAppRoute} />
                  <Route path={`/forgotPassword`} component={DynamicAppRoute} />
                  <Route
                    path={`${
                      isRootApp ? __ROOT_URL__ : `${__ROOT_URL__}:appId`
                    }`}
                    component={DynamicAppRoute}
                  />
                  {isRootApp === false && (
                    <Route path={__ROOT_URL__} exact component={Root} />
                  )}
                </Switch>
              </Router>
            </React.Suspense>
          </AppContext.Provider>
        </ApolloProvider>
      </div>
    </>
  );
};

export default App;
